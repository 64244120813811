import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhoWillSuiteKTP.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const WhoWillSuiteKTP = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Кому подойдет клиент-центрированная терапия</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Гипотетически, клиент-центрированная терапия может подойти всем:
                противопоказаний нет. Главное условие — человек должен быть
                готов к непростой и глубокой работе.
              </Text.Large>
              <Text.Large semiBold>
                В среднем, длительность терапии составляет 3 года, поскольку это
                не обычный разговор, а достаточно тернистый путь к самому себе.
                В процессе могут случаться не самые приятные открытия и
                осознавания. Но если пройти эту дорогу, в конце будет ждать
                нечто важное: умение жить, опираясь на себя, на свои внутренние
                переживания и ощущения. Свобода от чужих ожиданий, мечтаний,
                желаний. Понимание, что нужно именно тебе и как ты это хочешь.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Кому подойдет клиент-центрированная терапия" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
