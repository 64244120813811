import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhatIsKTP.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const WhatIsKTP = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Что такое клиент-центрированная психотерапия и как она работает" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Что такое клиент-центрированная психотерапия и как она работает
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Клиент-центрированная терапия (КЦТ) — это направление
                психологии, которое считает, что психотерапевт является не
                экспертом, а сопровождающим, потому что только сам клиент
                является экспертом своей жизни.
              </Text.Large>
              <Text.Large semiBold>
                В клиент-центрированном подходе есть доверие переживаниям
                клиента и его ощущениям. Нет задачи «рассекретить» и найти
                подвох. Считается, что человек изначально имеет в себе все
                необходимые ресурсы для преодоления трудностей, и нужно только
                создать правильные условия, чтобы человек научился пользоваться
                ими. Именно это и происходит в клиент-центрированной терапии.
                Психотерапевт старается создать условия и поддерживать клиента в
                его пути исследования себя.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
