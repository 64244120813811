import React from 'react'
import {
  Br,
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './HowToFindKTP.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToFindKTP = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Клиент-центрированная практика — как найти и выбрать психолога" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Клиент-центрированная практика —
                <Br /> как найти и выбрать психолога
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Самое важное условие эффективности клиент-центрированной
                психотерапии — сложившийся контакт между терапевтом и клиентом.
                Взаимоотношения должны быть надежными и доверительными.
              </Text.Large>
              <Text.Large semiBold>
                Поэтому процесс выбор клиент-центрированного психолога не
                заканчивается в момент записи на сессию. Важно сверяться со
                своими ощущениями во время консультаций. Если что-то вызывает
                сомнения или дискомфорт — стоит обсудить это с психологом или
                сменить специалиста.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
