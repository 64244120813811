import React from 'react'
import { Button, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Card,
  CardsContainer,
  Content,
  CustomGrid,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './QueriesKTP.styles'
import { Event as GAEvent } from '../../../components/GA'
import { cardData } from './cardData'

export const QueriesKTP = () => (
  <Wrapper>
    <section>
      <CustomGrid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H2>
                Запросы, с которыми работают по клиент-центрированному подходу
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Выбор психолога — важный шаг на пути к личностному росту и
                исцелению. Выбирая специалиста по нарративной терапии, обратите
                внимание на его опыт и образование. В YouTalk вы можете
                подобрать себе проверенного психолога, специализирующегося на
                нарративном подходе.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>

          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>
          <ButtonsWrapper>
            <Button.NewPrimary
              href="/wizard/"
              onClick={GAEvent.openSemeinyiPageFirstScreen}
              size="large"
              type="link"
            >
              Подобрать психолога
            </Button.NewPrimary>
          </ButtonsWrapper>
        </Content>
      </CustomGrid>
    </section>
  </Wrapper>
)
