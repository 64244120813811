import React from 'react'
import bloknotIcon from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/bloknot.svg'
import heartIcon from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/heart.svg'
import messageIcon from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/message.svg'
import studyIcon from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/study.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './KTPInYoutalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const KTPInYoutalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>
                Клиент-центрированная терапия онлайн в YouTalk
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Почему стоит подобрать клиент-центрированного психолога в
                YouTalk?
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={heartIcon} />
              <Text.Large semiBold>
                95% обратившихся находят своего психолога с первого раза
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Средний опыт работы наших специалистов — 7 лет
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Вы всегда можете обратиться за поддержкой в наш клиентский
                сервис, если вам что-то непонятно или вызывает трудности
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={bloknotIcon} />
              <Text.Large semiBold>
                Перед первой консультацией вы получите в подарок памятку,
                которая помогает подготовиться к терапии
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
