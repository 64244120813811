import heroIcon from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/howToFindKTP-image.svg'
import heroIcon1024 from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/howToFindKTP-image-1024.svg'
import heroIcon320 from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/howToFindKTP-image-320.svg'
import heroIcon414 from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/howToFindKTP-image-414.svg'
import heroIcon768 from '../../../../static/img/KlientTsentrirovannayaPsihoterapiya/howToFindKTP-image-768.svg'
import styled from 'styled-components'
import { size } from '../../../constants'

export const HeroImage = styled.img.attrs(() => ({}))`
  content: url(${heroIcon});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${heroIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    content: url(${heroIcon768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${heroIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${heroIcon320});
  }
`

export const Content = styled.div`
  padding: 72px 0 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 48px 0 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0 0;
  }
`

export const Br = styled.br`
  display: none;

  @media (max-width: ${size.md}) {
    display: block;
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #fff;
`

export const InfoContainer = styled.div`
  width: 100%;
`
