import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 32px;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column;
  }
`

export const ListImage = styled.img`
  width: 78px;
  height: 78px;

  @media (max-width: ${size.xs}) {
    width: 64px;
    height: 64px;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  max-width: 588px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  gap: 16px;

  @media (max-width: ${size.xs}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 16px;
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333333'};

  @media (max-width: ${size.xs}) {
    max-width: 200px;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

export const Wrapper = styled.div`
  background-color: #03a094;
`

export const InfoContainer = styled.div`
  max-width: 100%;

  @media (max-width: ${size.lg}) {
    max-width: 632px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }
`
