import React from 'react'
import {
  CardEightImage,
  CardFiveImage,
  CardFourImage,
  CardNineImage,
  CardOneImage,
  CardSevenImage,
  CardSixImage,
  CardThreeImage,
  CardTwoImage
} from './QueriesKTP.styles'

export const cardData = [
  {
    text: 'Не понимаю, кто я и чем хочу заниматься',
    image: <CardOneImage />
  },
  {
    text: 'Не складываются отношения с людьми',
    image: <CardTwoImage />
  },
  {
    text: 'Чувствую, что со мной что-то не так',
    image: <CardThreeImage />
  },
  {
    text: 'Не понимаю себя',
    image: <CardFourImage />
  },
  {
    text: 'Трудности на работе/учебе',
    image: <CardFiveImage />
  },
  {
    text: 'Тяжелые взаимоотношения с партнером',
    image: <CardSixImage />
  },
  {
    text: 'Излишняя эмоциональность (сильная злость, постоянная грусть, разъедающая ревность, сильный страх, или другие ярко проявляющиеся эмоции)',
    image: <CardSevenImage />
  },
  {
    text: 'Я вообще ничего не чувствую',
    image: <CardEightImage />
  },
  {
    text: 'Не получается построить дружбу',
    image: <CardNineImage />
  }
]
